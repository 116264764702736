<template>
  <div class="wrapper d-flex flex-column fill-height">
    <div class="gap-1 d-flex align-center">
      <simple-security-search
        class="security ml-2 my-2"
        clear-after-select
        label="Security"
        :value="selectedSecurity"
        @change="handleSecurityChange"
      />

      <aurora-btn
        v-if="canBorrow"
        color="secondary"
        data-test="borrow-button"
        :disabled="!hasTraderUserRole || !isSelectedSecurityTradable"
        timeframe="createLoans"
        :tooltip="selectedSecurity?.cannotTradeMessage"
        @click="newOrderSide = 'BORROWER'"
      >
        Borrow
      </aurora-btn>

      <aurora-btn
        v-if="canLend"
        color="secondary"
        data-test="lend-button"
        :disabled="!hasTraderUserRole || !isSelectedSecurityTradable"
        timeframe="createLoans"
        :tooltip="selectedSecurity?.cannotTradeMessage"
        @click="newOrderSide = 'LENDER'"
      >
        Lend
      </aurora-btn>
    </div>

    <div class="d-flex flex-column flex">
      <dashboard-panel title="Depth of Book">
        <marketplace-depth-of-book :cusip="$route.params.cusip" />
      </dashboard-panel>

      <dashboard-panel title="Orders">
        <marketplace-orders-table
          :actions="['view', 'close']"
          :items="marketplaceOrders"
          :omit-headers="['company', 'security', 'orderRef']"
          :show-select="false"
          @edit-order="editOrder = $event"
          @view-order="detailOrderRef = $event"
        />
      </dashboard-panel>

      <dashboard-panel title="Open Positions">
        <marketplace-symbol-overview-loans v-if="selectedSecurity" :security="selectedSecurity" />
      </dashboard-panel>
    </div>

    <marketplace-order-form-dialog
      v-if="newOrderSide"
      :new-order-security="selectedSecurity"
      :new-order-side="newOrderSide"
      @action="confirmationSnackbarOptions = $event"
      @close-modal="newOrderSide = null"
    />

    <marketplace-order-form-dialog
      v-if="editOrder"
      :order-ref="editOrder.orderRef"
      @action="confirmationSnackbarOptions = $event"
      @close-modal="editOrder = null"
    />

    <marketplace-order-details-dialog
      v-if="detailOrderRef"
      :order-ref="detailOrderRef"
      :should-retry-fetch="true"
      @action="confirmationSnackbarOptions = $event"
      @close-modal="detailOrderRef = null"
      @edit-order="editOrder = $event"
    />

    <marketplace-action-confirmation-snackbar
      v-if="confirmationSnackbarOptions"
      :options="confirmationSnackbarOptions"
      @close="confirmationSnackbarOptions = null"
      @view-order="detailOrderRef = confirmationSnackbarOptions.orderRef"
    />
  </div>
</template>

<script lang="ts">
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import { Security } from '@/modules/common/models';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import MarketplaceActionConfirmationSnackbar from '@/modules/marketplace/components/MarketplaceActionConfirmationSnackbar.vue';
import MarketplaceDepthOfBook from '@/modules/marketplace/components/MarketplaceDepthOfBook.vue';
import MarketplaceOrderDetailsDialog from '@/modules/marketplace/components/MarketplaceOrderDetailsDialog.vue';
import MarketplaceOrderFormDialog from '@/modules/marketplace/components/MarketplaceOrderFormDialog.vue';
import MarketplaceOrdersTable from '@/modules/marketplace/components/MarketplaceOrdersTable.vue';
import MarketplaceSymbolOverviewLoans from '@/modules/marketplace/components/MarketplaceSymbolOverviewLoans.vue';
import { ConfirmationSnackbarOptions } from '@/modules/marketplace/types/marketplace';
import { SocketEvents } from '@/store/store';
import { throttle } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { DepthOfBookResponse } from '@/modules/marketplace/models';
import { OmsOrder, OmsOrdersResponse } from '@/modules/marketplace/models';

@Component({
  provide() {
    return {
      gridStateKey: 'MarketplaceSymbolOverview',
    };
  },
  components: {
    DashboardPanel,
    MarketplaceDepthOfBook,
    MarketplaceSymbolOverviewLoans,
    MarketplaceOrderFormDialog,
    MarketplaceOrdersTable,
    SnapshotManager,
    MarketplaceOrderDetailsDialog,
    MarketplaceActionConfirmationSnackbar,
    SimpleSecuritySearch,
  },
  computed: {
    ...mapState(['socketEvents']),
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend', 'lastVisitedSymbolOverview']),
  },
  methods: {
    ...mapMutations(['updateLastVisitedSymbolOverview']),
  },
})
export default class MarketplaceSymbolOverview extends Vue {
  // store state
  protected canBorrow!: boolean;
  protected canLend!: boolean;
  protected socketEvents!: SocketEvents;
  protected hasTraderUserRole!: boolean;
  protected lastVisitedSymbolOverview!: Security | null;

  // store methods
  protected updateLastVisitedSymbolOverview!: (security: Security) => void;

  protected selectedSecurity: Security | null = null;
  protected depthOfBook: DepthOfBookResponse | null = null;
  protected marketplaceOrders: OmsOrdersResponse['data'] = [];
  protected detailOrderRef: string | null = null;
  protected editOrder: OmsOrder | null = null;
  protected newOrderSide: 'BORROWER' | 'LENDER' | null = null;
  protected dialogOrder: OmsOrder | null = null;
  protected confirmationSnackbarOptions: ConfirmationSnackbarOptions | null = null;
  protected throttledFetchOrdersByCusip = throttle(this.fetchOrdersByCusip, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get depthOfBookItems(): DepthOfBookResponse['data'] {
    return this.depthOfBook ? this.depthOfBook.data : [];
  }

  protected get isSelectedSecurityTradable(): boolean {
    return this.selectedSecurity?.cannotTradeMessage === null;
  }

  @Watch('$route.params.cusip', { immediate: true })
  protected updateSecurity(): void {
    // fix until we move from "hash" router to "history" router
    // in case user manually edits URL
    if (this.$route.params.cusip === undefined) {
      window.location.reload();
      return;
    }
    void this.fetch();
  }

  @Watch('socketEvents.marketplace.orders')
  protected onSocketEvents(): void {
    void this.throttledFetchOrdersByCusip();
  }

  protected async fetch(): Promise<void> {
    await Promise.all([this.fetchOpenLoansByCusip(), this.fetchOrdersByCusip()]);
    this.updateLastVisitedSymbolOverview(this.selectedSecurity as Security);
  }

  protected async fetchOpenLoansByCusip(): Promise<void> {
    if (
      this.selectedSecurity === null ||
      this.selectedSecurity.cusip !== this.$route.params.cusip
    ) {
      // only update when different from the current one
      // otherwise <simple-security-search> will keep re-rendering
      this.selectedSecurity = await this.$api.manualLoans.fetchSecurityByCusip(
        this.$route.params.cusip
      );
    }
  }

  protected async fetchOrdersByCusip(): Promise<void> {
    this.marketplaceOrders = await this.$api.marketplace.fetchOrdersList({
      showAll: false,
      cusip: this.$route.params.cusip,
    });
  }

  protected handleSecurityChange(security: Security | null): void {
    // Only change the page if user has selected a new security
    // (i.e. do nothing if user unselects security)
    if (security && this.$route.params.cusip !== security.cusip) {
      void this.$router.push({
        name: 'marketplace.symbol-overview',
        params: { cusip: security.cusip },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}

.security {
  max-width: 300px;
}

.gap-1 {
  gap: 1rem;
}
</style>
