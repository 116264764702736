<template>
  <v-card class="d-flex flex-column" elevation="0">
    <v-row v-if="fetchStatus === 'fetching'" class="justify-center pt-16 flex-column align-center">
      <fulfilling-bouncing-circle-spinner :animation-duration="1500" color="#4caf50" :size="50" />
      <h3 class="mt-2">Fetching order details...</h3>
      <h4 v-if="retryCount > 0">(This may take a bit longer)</h4>
    </v-row>

    <v-row
      v-else-if="fetchStatus === 'failed'"
      class="justify-center pt-16 flex-column align-center"
    >
      <v-alert dense type="error">
        {{ fetchError }}
      </v-alert>
    </v-row>

    <template v-else-if="fetchStatus === 'completed' && order">
      <v-card-title class="mb-0">
        <div class="d-flex align-center">
          <span class="headline">Order Details</span>
          <v-chip class="justify-center ml-4" :color="getStatus(order).color" small>
            <span :class="order.orderType === 'IOI' ? 'text-uppercase' : 'text-lowercase'">{{
              getStatus(order).label
            }}</span>
          </v-chip>
        </div>
        <v-spacer />
        <span class="text--primary headline-2">
          {{ order.orderRef }}
        </span>
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <div class="pr-4 pl-2 d-flex flex-column">
          <!-- Table with order summary -->
          <marketplace-order-summary :as-broker="asBroker" :order="order" />

          <v-row v-if="!asBroker">
            <!-- Action buttons -->
            <v-col cols="12">
              <div v-if="order.status === 'OPEN'" class="actions d-flex">
                <marketplace-toggle-route-btn
                  data-test="toggle-route-order"
                  :order-ref="order.orderRef"
                  :routing-status="order.routingStatus"
                  v-on="$listeners"
                />

                <marketplace-cancel-btn
                  v-if="order.status === 'OPEN'"
                  data-test="cancel-order"
                  :order-ref="order.orderRef"
                  v-on="$listeners"
                />

                <aurora-btn
                  v-if="order.status === 'OPEN'"
                  color="secondary"
                  data-test="edit-order"
                  :disabled="!hasTraderUserRole"
                  timeframe="createLoans"
                  @click="$emit('edit-order', order)"
                >
                  Edit
                </aurora-btn>
              </div>
            </v-col>
          </v-row>

          <v-divider class="mt-6 mb-0"></v-divider>

          <marketplace-order-history
            class="mt-4"
            :details="marketplaceOrderDetails"
            :is-inside-dialog="isInsideDialog"
            @open-loan-details="$emit('open-loan-details', $event)"
          />
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script lang="ts">
import MarketplaceCancelBtn from '@/modules/marketplace/components/MarketplaceCancelBtn.vue';
import MarketplaceOrderHistory from '@/modules/marketplace/components/MarketplaceOrderHistory.vue';
import MarketplaceOrderSummary from '@/modules/marketplace/components/MarketplaceOrderSummary.vue';
import MarketplaceToggleRouteBtn from '@/modules/marketplace/components/MarketplaceToggleRouteBtn.vue';
import {
  getEventTypeColor,
  getStatus,
  timeInForceAbbr,
} from '@/modules/marketplace/helpers/marketplace';
import { OmsHistoryResponse, OmsOrder } from '@/modules/marketplace/models';
import { SocketEvents } from '@/store/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

const FETCH_RETRY_DELAYS = [1 * 1000, 2 * 1000, 4 * 1000, 4 * 1000];

@Component({
  components: {
    MarketplaceOrderSummary,
    MarketplaceOrderHistory,
    MarketplaceToggleRouteBtn,
    MarketplaceCancelBtn,
  },
  props: {
    orderRef: String,
    asBroker: {
      type: Boolean,
      default: false,
    },
    shouldRetryFetch: {
      type: Boolean,
      default: false,
    },
    isInsideDialog: Boolean,
  },
  computed: {
    ...mapState(['socketEvents']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class MarketplaceOrderDetailsCard extends Vue {
  // props
  protected readonly orderRef!: string;
  protected readonly asBroker!: boolean;
  // useful when order has just been created
  // and the loan events are not available yet (but will be soon)
  protected readonly shouldRetryFetch!: boolean;
  protected readonly isInsideDialog?: boolean;

  // store state
  protected socketEvents!: SocketEvents;
  protected hasTraderUserRole!: boolean;

  protected marketplaceOrderDetails: OmsHistoryResponse | null = null;

  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  protected fetchError = '';
  protected getStatus = getStatus;
  protected getEventTypeColor = getEventTypeColor;
  protected timeInForceAbbr = timeInForceAbbr;

  protected retryCount = 0;
  protected fetchRetryDelays = FETCH_RETRY_DELAYS;
  protected abortController: AbortController | null = null;

  protected get order(): OmsOrder | null {
    return this.marketplaceOrderDetails === null ? null : this.marketplaceOrderDetails.order;
  }

  @Watch('socketEvents.marketplace.orders')
  protected onSocketEvents(payload: { orderRef: string }): void {
    if (payload.orderRef === this.orderRef) {
      // we don't need to throttle because we are interested in getting
      // all updates ASAP; token cancellation makes sure UI is in sync
      void this.fetch();
    }
  }

  @Watch('orderRef')
  protected onOrderRefChange(): void {
    this.retryCount = 0;
    this.fetchStatus = 'fetching';
    void this.fetch();
  }

  protected mounted(): void {
    void this.fetch();
  }

  protected async fetch(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    try {
      const res = this.asBroker
        ? await this.$api.marketplace.fetchAdminOrderDetails(
            this.orderRef,
            this.abortController.signal
          )
        : await this.$api.marketplace.fetchOrderDetails(this.orderRef, this.abortController.signal);
      if (!res) {
        // request was cancelled, there's another response arriving
        return;
      }

      this.marketplaceOrderDetails = res;
      this.fetchStatus = 'completed';
    } catch (e) {
      if (this.shouldRetryFetch) {
        await this.retryFetch(e);
      } else {
        this.fetchStatus = 'failed';
        this.fetchError = e as string;
      }
    }
  }

  protected async retryFetch(lastError: unknown): Promise<void> {
    if (this.retryCount >= this.fetchRetryDelays.length) {
      this.fetchStatus = 'failed';
      this.fetchError = lastError as string;
    } else {
      this.retryCount += 1;
      const delay = this.fetchRetryDelays[this.retryCount - 1];
      await this.$wait(delay);
      await this.fetch();
    }
  }
}
</script>
<style lang="scss" scoped>
.actions {
  gap: 1rem;
}
</style>
