/* eslint-disable vue/one-component-per-file */

import { Decimal } from 'decimal.js';
import { ColDef } from 'ag-grid-enterprise';
import { VChip } from 'vuetify/lib';
import { alignCenter, alignLeft, alignRight, comparator, component } from './utils';
import { getStatus, timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import {
  formatCompanyBoxId,
  formatCompanyName,
} from '@/modules/user-accounts/helpers/user-accounts';
import MarketplaceOrdersTableActions from '@/modules/marketplace/components/MarketplaceOrdersTableActions.vue';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { h } from 'vue';
import PrettyNumber from '@/modules/common/components/pretty-number/PrettyNumber.vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { OmsOrder } from '@/modules/marketplace/models';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
export { checkbox, cusip, side, ticker, settlementType } from './common';

const statusAdapter = component<{
  order: OmsOrder;
  viewOrder: (orderRef: string) => void;
  label: string;
  color: string;
}>(
  (props) => () =>
    h(
      VChip,
      {
        style: 'width: 5rem',
        class: `justify-center ${props.order.orderType === 'IOI' ? 'text-uppercase' : 'text-lowercase'}`,
        props: { color: props.color, small: true },
        on: { click: () => props.viewOrder(props.order.orderRef) },
      },
      props.label
    )
);
export function status({ viewOrder }: { viewOrder: (orderRef: string) => void }): ColDef<OmsOrder> {
  return {
    field: 'status',
    colId: 'status',
    headerName: 'Status',
    cellRendererSelector: (params) =>
      statusAdapter({
        order: params.data!,
        viewOrder,
        ...getStatus(params.data!),
      }),
    resizable: false,
    pinned: 'left',
    minWidth: 100,
    width: 100,
    ...alignCenter(),
  };
}

export function active(): ColDef<OmsOrder> {
  return {
    field: 'routingStatus',
    colId: 'routingStatus',
    headerName: 'Active',
    valueFormatter: (params) =>
      params.value === 'ROUTED' ? 'Active' : params.value === 'PENDING' ? 'Pending' : '–',
    ...alignLeft(),
  };
}

export function company(): ColDef<OmsOrder> {
  return {
    field: 'company',
    colId: 'company',
    headerName: 'Company',
    valueFormatter: (params) => formatCompanyName(params.value),
    ...alignLeft(),
  };
}

export const openQuantity = (): ColDef<OmsOrder> =>
  cols.quantity({ field: 'openQuantity', headerName: 'Open Qty' });

export function rate(): ColDef<OmsOrder> {
  return {
    ...cols.rate(),
    headerName: 'Rate Limit',
  } as ColDef<OmsOrder>;
}

export const execQty = (): ColDef<OmsOrder> => {
  return {
    ...cols.quantity({ field: 'filled', headerName: 'Exec Qty' }),
    valueFormatter: (params) => (params.value === 0 ? '–' : formatPrettyNumber(params.value)),
  };
};

const avgExecutionRateAdapter = component<{
  rate: number | string | Decimal | null;
}>((props) => () => (props.rate === null ? h('span', '–') : h(RateOutput, { props })));

export function avgExecRate(): ColDef<OmsOrder> {
  return {
    field: 'avgExecutionRate',
    colId: 'avgExecutionRate',
    headerName: 'Avg Exec Rate',
    cellRendererSelector: (params) =>
      avgExecutionRateAdapter({
        rate: params.value,
      }),
    comparator: (a, b) => comparator.decimal(a?.avgExecutionRate, b?.avgExecutionRate),
    cellDataType: 'text',
    ...alignRight(),
  };
}

export const totalQuantity = (): ColDef<OmsOrder> =>
  cols.quantity({ field: 'quantity', headerName: 'Total Qty' });

export const createTime = (): ColDef<OmsOrder> =>
  cols.timestamp({ field: 'createdAt', headerName: 'Create Time' });

export const updateTime = (): ColDef<OmsOrder> =>
  cols.timestamp({ field: 'updatedAt', headerName: 'Update Time' });

const orderTypeAdapter = component<{ orderType: string }>(
  (props) => () =>
    h(
      'span',
      { class: 'text-capitalize' },
      props.orderType === 'LIMIT' ? props.orderType.toLowerCase() : props.orderType
    )
);

export function orderType(): ColDef<OmsOrder> {
  return {
    field: 'orderType',
    colId: 'orderType',
    headerName: 'Type',
    cellRendererSelector: (params) =>
      orderTypeAdapter({
        orderType: params.value,
      }),
    ...alignLeft(),
  };
}

export function timeInForce(): ColDef<OmsOrder> {
  return {
    field: 'timeInForceType',
    colId: 'timeInForceType',
    headerName: 'Time in Force',
    valueFormatter: (params) => timeInForceAbbr(params.value),
    ...alignLeft(),
  };
}

const minQuantityAdapter = component<{ minQuantity: number }>(
  (props) => () =>
    props.minQuantity === 1 || props.minQuantity === 0
      ? h('span', '–')
      : h(PrettyNumber, { props: { value: props.minQuantity } })
);

export function minQuantity(): ColDef<OmsOrder> {
  return {
    field: 'minQuantity',
    colId: 'minQuantity',
    headerName: 'Min. Quantity',
    cellRendererSelector: (params) =>
      minQuantityAdapter({
        minQuantity: params.value,
      }),
    ...alignRight(),
  };
}

export function counterparties(): ColDef<OmsOrder> {
  return {
    field: 'counterparties',
    colId: 'counterparties',
    headerName: 'Counterparties',
    valueFormatter: (params) =>
      params.value.length ? params.value.map(formatCompanyBoxId).join(', ') : 'Any',
    ...alignLeft(),
  };
}

export function orderRef(): ColDef<OmsOrder> {
  return {
    field: 'orderRef',
    colId: 'orderRef',
    headerName: 'OrderRef',
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  order: OmsOrder;
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: OmsOrder) => void;
  actions?: string[];
}>((props) => () => h(MarketplaceOrdersTableActions, { props }));

export function actions({
  viewOrder,
  editOrder,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  actions = ['view', 'edit', 'route', 'unroute', 'cancel'],
}: {
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: OmsOrder) => void;
  actions?: string[];
}): ColDef<OmsOrder> {
  return {
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      actionsAdapter({
        order: params.data!,
        viewOrder,
        editOrder,
        actions,
      }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
